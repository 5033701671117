import moment from 'moment-timezone';
import { validateCustomDuration, validateCustomStartTime, validateCustomWorkerCounts } from './validators';

//TODO - move constants into their own file
export const MAX_OVERNIGHT_SHIFT_LENGTH_HOURS = 12;

export const defaultWorkerTypes = [
    { level: 1, positionName: 'Expert' },
    { level: 2, positionName: 'Senior' },
    { level: 3, positionName: 'Intermediate' },
    { level: 4, positionName: 'Entry' }
];

export const shiftStartsAndEndsOnSameDay = (startsAt, endsAt) => moment(endsAt).dayOfYear() === moment(startsAt).dayOfYear()

//It's a valid overnight date if the time of endsAt (ignoring the date) is before the time of startsAt (ignoring the date)
export const isValidOvernightDate = (startsAt, endsAt) => {
    const startDateTime = moment(startsAt);
    const endDateTime = moment(endsAt);
    const adjustedEndDateTime = endDateTime.year(startDateTime.year()).dayOfYear(startDateTime.dayOfYear());
    return moment.duration(adjustedEndDateTime.diff(startDateTime)) < 0
};

//Calculate hours between start and end
//assuming both on same day.
//If overnight result will be negative - overnight flag adjusts result
export const calculateShiftLength = (start, end, overnight = false) => {
    const startDateTime = moment(start);
    const endDateTime = moment(setDateOfDateTime(end, start));
    const result = moment.duration(endDateTime.diff(startDateTime)).asHours();
    return overnight && result < 0 ? 24 + result : result;
};

export const setDateOfDateTime = (dateTime, date) => {
    const momentDate = moment(date);
    return moment(dateTime).year(momentDate.year()).month(momentDate.month()).date(momentDate.date()).toDate();
}

export const setTimeOfDateTime = (dateTime, time) => {
    const momentTime = moment(time);
    return moment(dateTime).hours(momentTime.hours()).minutes(momentTime.minutes()).seconds(momentTime.seconds()).toDate();
}

//Add event listeners to inputs with specified classname
//to prevent mouse movements adjusting numbers 
export const addMouseWheelListeners = (document) => {
    const inputs = document.getElementsByClassName('worker-count');
    for (let input of inputs) {
        if (input && input.onscroll === null) {
            input.addEventListener("mousewheel", (e) => e.preventDefault());
        }
    }
}

//Calculates total duration of dates that are not marked for deletion
export const aggregateTotalHours = (dates) => {
    return dates.reduce((total, date) => {
        return date._destroy === true ? total : total + moment.duration(moment(date.endsAt).diff(moment(date.startsAt))).asHours();
    }, 0);
};

//Compare hour and minute of start and end to see if they are different
export const timesHaveNotChanged = (projectDate, globalStart, globalEnd) => {
    const localStartsAt = moment(projectDate.startsAt);
    const localEndsAt = moment(projectDate.endsAt);
    const globalStartsAt = moment(globalStart);
    const globalEndsAt = moment(globalEnd);
    return globalStartsAt.hour() === localStartsAt.hour()
        && globalStartsAt.minute() === localStartsAt.minute()
        && globalEndsAt.hour() === localEndsAt.hour()
        && globalEndsAt.minute() === localEndsAt.minute();
}

//Validators for projectDates addition and editing

//Manual validation for project dates added with global worker counts

//This method is called to manually set errors in situations where we can't use automatic validation
//For example if the global worker counts are set to zero then all uncustomized dates
//become invalid.
export const checkProjectDatesValidity = (getValues, arrayName, clearErrors, setError) => {
    const projectDates = getValues(arrayName);
    clearErrors(`${arrayName}`);
    projectDates.forEach((projectDate, index) => {
        const zeroWorkersErr = validateCustomWorkerCounts(getValues, arrayName, index, clearErrors);
        const timeErr = validateCustomDuration(getValues, arrayName, index, clearErrors);
        const startTimeErr = validateCustomStartTime(getValues, arrayName, index, getValues("marketTimezoneISOName"), clearErrors);
        if (zeroWorkersErr) {
            projectDate.projectWorkersAttributes.forEach((_, index2) => {
                setError(`${arrayName}.${index}.projectWorkersAttributes.${index2}.totalAmount`, { type: 'count', message: 'At least one worker must be selected' })
            })
        } else {
            projectDate.projectWorkersAttributes.forEach((_, index2) => {
                clearErrors(`${arrayName}.${index}.projectWorkersAttributes.${index2}.totalAmount`)
            })
        }
        if (timeErr) {
            setError(`${arrayName}.${index}.startsAt`, { type: 'duration', message: 'Start and end times must be at least four hours apart' })
            setError(`${arrayName}.${index}.endsAt`, { type: 'duration', message: 'Start and end times must be at least four hours apart' })
        } else {
            clearErrors(`${arrayName}.${index}.startsAt.duration`);
            clearErrors(`${arrayName}.${index}.endsAt.duration`);
        }
        if (startTimeErr) {
            setError(`${arrayName}.${index}.startsAt`, { type: 'hourFromNow', message: 'Start time must be an hour after the current time' })
        } else {
            clearErrors(`${arrayName}.${index}.startsAt.hourFromNow`)
        }
    })
}

//For array errors - given an object that (we hope) contains
//a 'message' key find that message
const extractErrorMessage = (errorObject) => {
    const keys = Object.keys(errorObject);
    if (keys.length === 0) return "";
    if (keys.indexOf("message") > -1) {
        return errorObject.message;
    } else {
        for (let key of keys) {
            return extractErrorMessage(errorObject[key])
        }
    }
}

//Extracts the message for the given element
//If the extracted error object is an array reduce the error messages
export const getErrorMessage = (errors, fieldName) => {
    if (!errors || !fieldName) return;
    const fieldParts = fieldName.split('.');
    let errorObject = errors;
    fieldParts.forEach(part => {
        errorObject = errorObject ? errorObject[part] : undefined;
        if (!errorObject) {
            return "";
        }
    });
    if (errorObject?.message) {
        return errorObject.message
    } else if (Array.isArray(errorObject)) {
        const reducedErrors = errorObject.reduce((acc, item) => {
            const itemErr = extractErrorMessage(item);
            if (itemErr !== "" && !acc.includes(itemErr)) {
                acc.push(itemErr)
            }
            return acc;
        }, []);
        return reducedErrors.join(',');
    } else return "";
};

//some numbers have format (xxx) yyy-zzzz
//some have +1xxxyyyzzzz
const formatPhoneNo = (phoneNo) => {
    const cleaned = ('' + phoneNo).replace(/^\+1{1}|\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

const unFormatPhoneNo = (phoneNo) => {
    const cleaned = ('' + phoneNo).replace(/^\+1{1}|\D/g, '');
    return `+1${cleaned}`;
};

//for historical projects site requirements may be in the project_site_requirements table (returned as 'site_requirements' - an array of site_requirement objects)
//or the site_requirement field of the project (returned as 'site_requirement' - semicolon separated string)
//in projects after this update they should be in both. Currently all site requirements will appear in the drop down
//modify this method if that changes
export const processIncomingSiteRequirements = (siteRequirements, siteRequirement, otherSiteRequirements, allSiteRequirements) => {
    const siteRequirementsOptions = (siteRequirements || []).map(item => ({
        value: item.id,
        label: item.name
    }));
    const siteRequirementsFromProjectRecord = (siteRequirement ? siteRequirement.split(';') : [])
        .map(srp => {
            const matchingEntry = allSiteRequirements.find(item => item.label.trim() === srp.trim());
            const existingEntry = matchingEntry ? siteRequirementsOptions.find(item => item.value === matchingEntry.value) : undefined;
            return matchingEntry ? existingEntry ? undefined : matchingEntry : { value: srp, label: srp }
        }).filter(item => item);
    const combinedSiteRequirements = siteRequirementsOptions.concat(siteRequirementsFromProjectRecord);
    const others = otherSiteRequirements ? otherSiteRequirements.split(';')
        .map(otherRequirement => {
            //all entries here should be manual ones
            //but check for duplicates
            const existingEntry = combinedSiteRequirements.find(item => item.label === otherRequirement);
            return existingEntry ? undefined : { label: otherRequirement, value: otherRequirement }
        }).filter(item => item) : [];
    return {
        siteRequirement: combinedSiteRequirements.concat(others),
        staticSiteRequirements: combinedSiteRequirements,
        otherSiteRequirements
    };
};

export const processOutgoingSiteRequirements = (requirements) => {
    //we add three properties to the payload:
    //siteRequirementIds: an array of ids used to populate the project_site_requirements table
    //siteRequirement: a semicolon separated list of the labels of all predefined site reqiurements
    //otherSiteRequirements: a semicolon separated list of all free-typed requirements
    const regularRequirements = requirements.filter(item => item.label !== item.value);
    const additionalRequirements = requirements.filter(item => item.label === item.value);
    return {
        siteRequirement: regularRequirements.map(item => item.label).join(';'),
        siteRequirementIds: regularRequirements.map(item => item.value),
        otherSiteRequirements: additionalRequirements.map(item => item.label).join(';')
    };
}

export const sortProjectWorkerTypes = (projectWorkerTypes) => projectWorkerTypes
    .sort((a, b) => {
        const dateDiff = a.projectDateId - b.projectDateId;
        const levelDiff = b.level - a.level; //highest first
        return dateDiff === 0 ? levelDiff : dateDiff;
    });

export const consolidateScheduledWorkers = (scheduledWorkers) => scheduledWorkers
    .reduce((acc, entry) => {
        if (!entry.length) return acc;
        const entryLevel = entry[0].level;
        //If we find an existing entry in acc with the same level concat otherwise push
        const existingEntryIndex = acc
            .findIndex(scheduledWorkers => scheduledWorkers.some(sw => sw.level === entryLevel));
        if (existingEntryIndex > -1) {
            const updatedEntry = acc[existingEntryIndex].concat(entry);
            acc[existingEntryIndex] = updatedEntry;
        } else acc.push(entry);
        return acc;
    }, []);

export const loadProjectDetails = ({
    projectDetails,
    markets,
    foremen,
    siteRequirements
}) => {
    if (!projectDetails) return;
    const convertedSiteRequirements = processIncomingSiteRequirements(
        projectDetails.siteRequirements,
        projectDetails.siteRequirement,
        projectDetails.otherSiteRequirements,
        siteRequirements);
    const selectedMarket = (markets || []).find(market => market.id === parseInt(projectDetails.market));
    //need to match foreman phone no as email may not be set
    const selectedForeman = foremen.find(foreman => formatPhoneNo(projectDetails.pocPhone) === formatPhoneNo(foreman.phone));
    const workerTypes = selectedMarket?.worker_types || [];
    const reducedProjectWorkerTypes = sortProjectWorkerTypes(projectDetails.workerTypes);
    const projectDates = projectDetails.projectDates.map(pd => {
        const projectWorkersAttributes = reducedProjectWorkerTypes
            .filter(wt => wt.projectDateId === pd.id)
            .sort((a, b) => a.level - b.level);
        //add worker types with zero values to make update easier
        //we'll remove entries with zero workers before updating
        let allProjectWorkersAttributes = projectWorkersAttributes;
        (selectedMarket?.workerTypes || []).forEach(workerType => {
            const existingPwa = projectWorkersAttributes.find(item => item.workerTypeId === workerType.id);
            if (!existingPwa) {
                allProjectWorkersAttributes.push({
                    id: null,
                    costCents: workerType.costCents,
                    level: workerType.level,
                    levelDescription: workerType.levelDescription,
                    positionName: workerType.positionName,
                    projectDateId: pd.id,
                    costCurrency: workerType.costCurrency,
                    filledAmount: 0,
                    totalAmount: 0,
                    toolsNeeded: workerType.toolsNeeded,
                    workerTypeId: workerType.id
                });
            }
        });
        allProjectWorkersAttributes = allProjectWorkersAttributes.sort((a, b) => b.level - a.level);
        const scheduledWorkersForThisDate = consolidateScheduledWorkers(pd.scheduledWorkers);
        const { startsAt, endsAt, timezone, ...rest } = pd;
        const date = moment.tz(startsAt, timezone).format('YYYY-MM-DD');
        const startDateTimeAtMarketTimezone = new Date(moment(startsAt).tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
        const endDateTimeAtMarketTimezone = new Date(moment(endsAt).tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
        const isOvernight = isValidOvernightDate(startDateTimeAtMarketTimezone, endDateTimeAtMarketTimezone);
        const isNewDate = pd.id === null;
        const shiftLengthHours = calculateShiftLength(startDateTimeAtMarketTimezone, endDateTimeAtMarketTimezone, isOvernight);
        const isOverMaxHours = isOvernight && shiftLengthHours > MAX_OVERNIGHT_SHIFT_LENGTH_HOURS;
        return {
            ...rest,
            date,
            timezone,
            startsAt: startDateTimeAtMarketTimezone,
            endsAt: endDateTimeAtMarketTimezone,
            projectWorkersAttributes: allProjectWorkersAttributes,
            scheduledWorkers: scheduledWorkersForThisDate,
            overnight: isOvernight,
            newDate: isNewDate,
            overMaxLength: isOverMaxHours,
            totalHours: shiftLengthHours
        }
    });
    const converted = {
        id: projectDetails.id,
        projectName: projectDetails.projectName,
        referenceNumber: projectDetails.referenceNumber,
        description: projectDetails.description,
        startingAddress: projectDetails.address,
        unit: projectDetails.unit || '',
        siteRequirement: convertedSiteRequirements.siteRequirement,
        foremanId: selectedForeman?.id,
        pocName: selectedForeman ? selectedForeman.name : projectDetails.pocName,
        pocEmail: selectedForeman ? selectedForeman.email : projectDetails.pocEmail,
        pocPhone: selectedForeman ? formatPhoneNo(selectedForeman.phone) : formatPhoneNo(projectDetails.pocPhone),
        otherManufacturer: projectDetails.otherManufacturer,
        otherSiteRequirements: convertedSiteRequirements.otherSiteRequirements,
        numDays: projectDetails.numDays,
        badgingRequired: projectDetails.badgingRequired,
        badgingFile: projectDetails.badgingFile || '',
        wantsReplacements: projectDetails.wantsReplacements.toString(),
        startEqProjectAddress: projectDetails.startEqProjectAddress,
        preferredWorkers: projectDetails.preferredWorkers || '',
        requiredWorkers: projectDetails.requiredWorkers || '',
        manufacturerIds: (projectDetails.productLines || []).map(item => item.manufacturerId),
        manufacturers: (projectDetails.productLines || []).map(item => ({ value: item.manufacturerId, label: item.manufacturer })),
        marketId: projectDetails.market,
        market: selectedMarket,
        marketTimezoneISOName: selectedMarket?.timezone,
        totalCostPerHourCents: projectDetails.totalCostPerHourCents,
        totalCostPerHourCurrency: projectDetails.totalCostPerHourCurrency,
        totalHours: projectDetails.totalHours,
        totalCostOfProject: projectDetails.totalCostOfProject,
        projectDatesAttributes: projectDates,
        productLines: projectDetails.productLines,
        workerTypes,
    };
    return converted;
}

export const getTimezoneOffset = (date, timezone) => {
    const offset = moment(date).tz(timezone).utcOffset();
    const sign = offset < 0 ? '-' : '';
    const offsetHrs = `${Math.abs(offset / 60)}`.padStart(2, '0');
    const offsetMins = `${Math.abs(offset % 60)}`.padStart(2, '0');
    return `${sign}${offsetHrs}:${offsetMins}`;
}
export const transformProjectDatesAttributes = (projectDates, timezone) => {
    return projectDates
        .filter(pd => {
            const today = moment().tz(timezone).startOf("day");
            const date = moment.tz(pd.date, timezone).startOf("day");
            return date.isSameOrAfter(today);
        })
        .map((item) => {
        const timezoneOffset = getTimezoneOffset(item.startsAt, timezone);
        const startDateTime = `${moment(item.startsAt).format('YYYY-MM-DD HH:mm:ss')}.000${timezoneOffset}`
        const endDateTime = `${moment(item.endsAt).format('YYYY-MM-DD HH:mm:ss')}.000${timezoneOffset}`

        return {
            ...item,
            startsAt: startDateTime,
            endsAt: endDateTime,
            timezone: item.timezone || '',
            projectWorkersAttributes: (item.projectWorkersAttributes || []).filter(item => parseInt(item.totalAmount) > 0 || item.id !== null),
        };
    });
};

export const buildCreateProjectObject = (formData) => {
    const manufacturerIds = formData.manufacturers ? formData.manufacturers.map(manufacturer => manufacturer.value) : [];
    const processedSiteRequirements = processOutgoingSiteRequirements(formData.siteRequirement)
    const projectDatesAttributes = transformProjectDatesAttributes(formData.projectDatesAttributes || [], formData.marketTimezoneISOName);
    return {
        projectName: formData.projectName,
        referenceNumber: formData.referenceNumber,
        description: formData.description || null,
        address: formData.startingAddress,
        unit: formData.unit || null,
        preferredWorkers: formData.preferredWorkers,
        requiredWorkers: formData.requiredWorkers,
        badgingRequired: formData.badgingRequired,
        uploadAttributes: formData.badgeFileData ? {
            file: formData.badgeFileData,
        } : null,
        siteRequirement: processedSiteRequirements.siteRequirement,
        siteRequirementIds: processedSiteRequirements.siteRequirementIds,
        otherSiteRequirements: processedSiteRequirements.otherSiteRequirements,
        numDays: projectDatesAttributes.length,
        totalHours: formData.totalHours,
        pocName: formData.pocName,
        pocPhone: unFormatPhoneNo(formData.pocPhone),
        pocEmail: formData.pocEmail,
        otherManufacturer: formData.otherManufacturer || null,
        wantsReplacements: formData.wantsReplacements,
        startEqProjectAddress: formData.startEqProjectAddress,
        selectDates: projectDatesAttributes.map(date => date.startsAt),
        projectDatesAttributes: projectDatesAttributes,
        manufacturerIds: manufacturerIds.length > 0 ? manufacturerIds : null,
        marketId: parseInt(formData.marketId),
        foremanId: formData.foremanId,
    };
};

export const buildEditProjectObject = (formData) => {
    const manufacturerIds = formData.manufacturers ? formData.manufacturers.map(manufacturer => manufacturer.value) : [];
    const processedSiteRequirements = processOutgoingSiteRequirements(formData.siteRequirement)
    const projectDatesAttributes = transformProjectDatesAttributes(formData.projectDatesAttributes || [], formData.marketTimezoneISOName, true);
    return {
        projectName: formData.projectName,
        referenceNumber: formData.referenceNumber,
        description: formData.description || null,
        address: formData.startingAddress,
        unit: formData.unit || null,
        badgingRequired: formData.badgingRequired,
        uploadAttributes: formData.badgeFileData ? {
            file: formData.badgeFileData,
        } : null,
        siteRequirement: processedSiteRequirements.siteRequirement,
        siteRequirementIds: processedSiteRequirements.siteRequirementIds,
        otherSiteRequirements: processedSiteRequirements.otherSiteRequirements,
        preferredWorkers: formData.preferredWorkers,
        requiredWorkers: formData.requiredWorkers,
        numDays: projectDatesAttributes.length,
        totalHours: formData.totalHours,
        pocName: formData.pocName,
        pocPhone: unFormatPhoneNo(formData.pocPhone),
        pocEmail: formData.pocEmail,
        otherManufacturer: formData.otherManufacturer || null,
        wantsReplacements: formData.wantsReplacements,
        startEqProjectAddress: formData.startEqProjectAddress,
        selectDates: projectDatesAttributes.map(attr => attr.startsAt),
        projectDatesAttributes: projectDatesAttributes,
        manufacturerIds: manufacturerIds.length > 0 ? manufacturerIds : null,
        marketId: parseInt(formData.marketId),
        foremanId: formData.foreman,
    };
};

export const getProjectStatus = (project) => {
    const now = moment().tz(project.timezone);
    const startsAt = moment.utc(project.starts_at).tz(project.timezone);
    const endsAt = moment.utc(project.ends_at).tz(project.timezone);
    const isCancelled = project.total_spots === 0;

    if (isCancelled) {
        return 'Cancelled';
    }

    if (now.isBetween(startsAt.clone().subtract(2, 'hours'), startsAt)) {
        return 'Workers in Transit';
    }

    if (now.isBetween(startsAt, endsAt)) {
        return 'In Progress';
    }

    if (now.isBefore(startsAt)) {
        return 'Upcoming';
    }

    if (now.isAfter(endsAt)) {
        return 'Completed';
    }

    return 'Unknown';
};