import isEmail from 'validator/lib/isEmail'
import moment from 'moment'
import { timeDiffFromNowHours } from './utils'
import { isMobilePhone, isStrongPassword } from 'validator'
import { calculateShiftLength, isValidOvernightDate } from './projectUtils'


/**
 * Compose Validators (Function for combining validators)
 *
 * @param {*} validators - Validators as params will be composed together.
 * @returns {function(...[*]): (*)} - Returns first error message from validators.
 *
 * @example
 * // first validates with validateRequiredOld() then validateMinLength(8)
 * composeValidators(
 *   validateRequiredOld(),
 *   validateMinLength(8)
 * )
 *
 */
export const composeValidators = (...validators) => (...validatorParams) =>
  validators.reduce(
    (error, validator) => error || validator(...validatorParams),
    undefined,
  )

/**
 * Email - for useForm validators
 * @param {string} 
 * @returns {string} - string if value is not an email address.
 */
export const validateEmail = (value) => isEmail(value, {allow_underscores: true}) ? undefined : "Please supply a valid email address"

/**
 * Password - for useForm validators
 * @param {string} 
 * @returns {string} - string if value is not a valid password.
 */
const strongPasswordOptions = {
  minLength: 8,
  minLowercase:0,
  minUppercase:0,
  minNumbers:0,
  minSymbols:0
}
export const validatePassword = (value) => isStrongPassword(value, strongPasswordOptions) ? undefined: "Password must be at least 8 characters"
/**
 * Mobile number - for useForm validators
 * @param {string}
 * @returns {string | undefined} - error message if value is not a valid phone number
 */
 export const validateMobileNumber = (value) => {
  return isMobilePhone(value, ['en-US']) ? undefined : "Please enter a valid phone number"
 }
/**
 * Email Validator
 */
export const email = value => (
  value && !isEmail(value)
    ? 'Please provide a valid email address.'
    : undefined
)

/**
 * Is a Number
 *
 * @param {string} 
 * @returns {string} - error message if value is not a number.
 */
export const validateIsNumber = (value) => 
  value !== undefined && isNaN(value) 
  ? 'Entry must be only numbers'
  : undefined


/**
 * Match
 *
 * @param {string} fieldName                   - The name of the field you want to validate is a match.
 * @param {string} [msg='Does not match.']     - Error message. Can include HTML.
 * @returns {function(string, object): string} - Function that returns an error message if values do not match.
 */
export const validateMatch = (
  fieldName,
  msg = 'Does not match.',
) => (value, allValues) => value !== allValues[fieldName] ? msg : undefined

/**
 * Min Length
 *
 * @param {number} minLength - Length (characters or array items) value must be less than or equal to.
 * @param {string} [msg=`Must be minimum of ${minLength}.`] - Error message. Can include HTML.
 * @returns {function(string): string} - Function that returns the error message if value is less than minLength.
 */
export const validateMinLength = (
  minLength,
  msg = `Must be minimum of ${minLength}.`,
) => value => (value.length < minLength) && value ? msg : undefined

/**
 * Required
 *
 * @param {string} [msg='Required.']   - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value is empty.
 */
export const validateRequiredOld = (
  msg = 'Required.',
  // check against length because unfilled checkboxes produces empty array
) => value => value && value.length !== 0 ? undefined : msg

/**
 * Required (for use in useForm controls)
 *
 * @param {string} 
 * @returns {string} - returns an error message if value is empty.
 */
export const validateRequired = (value) => {
  const msg = 'Required';
  return value && value.length !== 0 ? undefined : msg
}


/**
 * Required if active
 *
 * @param {boolean} active   - Determines if validation is active.
 * @param {string} [msg='Required.']   - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value is empty and active.
 */
export const validateConditionalRequired = (
  active,
  msg = 'Required.',
  // check against length because unfilled checkboxes produces empty array
) => value => !active || (value && value.length !== 0) ? undefined : msg

/**
 * True
 * @param {value} - must be boolean and true to pass
 * @returns {string} - error message or undefined
 */
export const validateIsTrue = (val, message) => {
  const isTrue = typeof val === 'boolean' && val === true;
  return isTrue ? undefined : message || "Not true"
}
/**
 * Required Boolean
 *
 * @param {string} [msg='Required.']   - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value is not true or false.
 */
export const validateRequiredBool = (
  msg = 'Required.',
) => value => typeof value === 'boolean' ? undefined : msg

/**
 * Min Number
 *
 * @param {number} min                 - Number which value can't be less than.
 * @param {string} [msg='...']         - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value less than min.
 */
export const validateMinNumber = (
  min,
  msg = `Must be a minimum of ${min}`,
) => value => (value < min) && value ? msg : undefined

/**
 * Date is in Array of Dates
 *
 * @param {Date}   value - The date object we wish to see if in array.
 * @param {Date[]} array - Array of date objects
 * @returns {boolean}    - True if value date exists in array otherwise false.
 */
export const validateDateIsInArray = (array, value) => !!array.find(item => item.getTime() === value.getTime())

/**
 * 
 * @param {*} value - The selected value
 * @param {*} placeholder - The placeholder message
 * @returns {function(string): string} - Returns error message or undefined
 */
export const validateSelectedOption = (value, placeholder) => {
  return value && value !== placeholder ? undefined : "Please select an option"
};
/**
 * Today is in Array of Dates from react-day-picker
 *
 * @param {Date[]} dates  - Array of date objects from react-day-picker.
 * @returns {boolean}     - True if dates contains today.
 */
export const validateIsToday = (dates) => {

  let isToday = false

  // if values exist in array
  if (dates.length) {
    // get now in format of react-day-picker to prevent issues when close to midnight etc
    const todayDate = moment({ hour: 12, minute: 0, seconds: 0, milliseconds: 0 }).toDate()

    // is today?
    isToday = !!validateDateIsInArray(dates, todayDate)
  }

  return isToday
}

/**
 * Late Call: When today is selected and time is within two hours
 *
 * @param {String} startTime - Start time to check against current time. Must be in 24 hour format. (HTML5 time field).
 * @param {Date[]} dates     - Array of date objects from react-day-picker
 * @returns {boolean}        - True if dates contains today's date and startTime is within 2 hours of now
 */
export const validateIsLateCall = (startTime, dates) => {

  let isToday = false,
    withinTwo = false

  // if we have values to check
  if (dates.length && startTime) {

    // is today?
    isToday = !!validateIsToday(dates)

    // calc time difference from now
    const timeDiff = timeDiffFromNowHours(startTime)

    // is within 2 hours of now?
    withinTwo = timeDiff <= 2
  }

  return (isToday && withinTwo)
}

/**
 * Is time before now when today's date is selected? (must be used with HTML5 time field)
 *
 * @param {string} timeField       - The name of the react-day-picker field you want to check if contains today.
 * @param {string} [msg='...']         - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error if dates contains today's date and time is before now
 */

export const validateIsBeforeNow = (
  timeField,
  msg = 'Time/date can\'t be in past.',
) => (value, allValues) => {

  let isToday = false
  let isBeforeTimeNow = false
  const dates = allValues[timeField]

  // if values exist
  if (dates.length && value) {

    // is today?
    isToday = !!validateIsToday(dates)

    // calc time difference from now
    const timeDiff = timeDiffFromNowHours(value)

    // is time before now?
    isBeforeTimeNow = timeDiff < 0
  }

  return (isToday && isBeforeTimeNow) ? msg : undefined
}

/**
 * Is it a larger time frame provided hours?
 *
 * @param {string} attributeKey    - The key of the react-day-picker field
 * @param {number} hours           - The number hours it must equal or exceed
 * @param {string} [msg='...']     - Error Message. Can include HTML.
 * @returns {function(*, *): *}    - Function that returns an error if time frame is shorter then provided hours
 */

export const validateMinHours = (
  attributeKey,
  hours,
  msg = `Time frame must be longer than ${hours} hours.`,
) => (values, allValues) => {
  let startsAt
  let endsAt
  if (attributeKey === 'same') {
    startsAt = allValues.startsAt
    endsAt = allValues.endsAt
  } else {
    startsAt = allValues.projectDatesAttributes[attributeKey].startsAt
    endsAt = allValues.projectDatesAttributes[attributeKey].endsAt
  }
  const checkedEndsAt = startsAt > endsAt ? endsAt.add(1, 'days') : endsAt
  let totalHours = Math.abs(moment.duration(moment(checkedEndsAt).diff(moment(startsAt))).asHours())
  totalHours = totalHours > 24  ? totalHours - 24 : totalHours

  return (totalHours <= hours) ? msg : undefined
}

export const isAnyRangeOverFifteenHours = (ranges) => {
  let over = false;
  for (const range in ranges) {

    const {endsAt, startsAt} = ranges[range];

    endsAt.set("date", startsAt.date())
    endsAt.set("month", startsAt.month())
    
    let hours = moment.duration(
      moment(endsAt).diff(startsAt)
    ).asHours()

    let over = Math.abs(hours) > 15
    if (over) break;
  }
  return over;
}

/**
 * Is the current value a percentage?
 *
 * @param {string} rdpDatesArray       - The name of the react-day-picker field you want to check if contains today.
 * @param {string} [msg='...']         - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error if dates contains today's date and time is before now
 */

export const validatePercentage = (str) => {
  const p = parseFloat(str)
  return !(isNaN(p) || p < 0 || p > 100)
}

/**
 * Validate if number is whole positive number
 *
 * @param {string} msg                 - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error if value is not a whole positive number.
 */
export const validatePositiveWhole = (
  msg = 'Must be a whole number of 1 or more.'
) => value => value && (value % 1 !== 0 || value < 0) ? msg : undefined

//Automatic validators

  //automatic validation for dateTime pickers when adding new dates
  export const validateGlobalDuration = (getValues) => {
    const globalOvernight = getValues("overnightProject"); 
    const timeDifference = calculateShiftLength(getValues("globalStartsAt"), getValues('globalEndsAt'), globalOvernight);
    return timeDifference < 0
      ? "End time has been entered earlier than start time. If this is correct please click overnight project"
      : timeDifference < 4
        ? 'Start and end times must be at least four hours apart'
        : undefined;
};

//automatic validation for worker counts when adding new dates
export const validateGlobalCounts = (getValues) => {
  const values = getValues("globalWorkers");
  const totals = values?.reduce((acc,item) => {
      const tot = parseInt(item.totalAmount);
      return isNaN(tot) ? acc:  acc + tot
  },0);
  if (totals === 0) {
      return "At least one worker must be selected"
  }
};


//automatic validation for dateTime pickers when customizing new dates
   //to ensure interval is 4 hrs or more
   export const validateCustomDuration = (getValues, arrayName = 'projectDatesAttributes', index) => {
    const startTime =  getValues(`${arrayName}.${index}.startsAt`);
    const endTime =   getValues(`${arrayName}.${index}.endsAt`);
    const globalOvernight = getValues('overnightProject');
    const overnightFlag = getValues(`${arrayName}.${index}.overnight`);
    const customisedFlag = getValues(`${arrayName}.${index}.customized`);
    const isOvernight = (globalOvernight || overnightFlag || customisedFlag) && isValidOvernightDate(startTime, endTime);
    if (calculateShiftLength(startTime, endTime, isOvernight) < 4) 
    {
        return 'Start and end times must be at least four hours apart'
    } 
};

//automatic validation for date time pickers to ensure start date
//is an hour after the current time
export const validateCustomStartTime = (getValues, arrayName = 'projectDatesAttributes', index, timezone ) => {
  const startTime =  getValues(`${arrayName}.${index}.startsAt`);
  const startTimeAsString = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
  const nowAsString = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  const startTimeAsMarketTime = moment(startTimeAsString);
  const currentTimeAtMarketPlusOneHour = moment(nowAsString).add(1, "hour");
  const difference =  moment(startTimeAsMarketTime).diff(currentTimeAtMarketPlusOneHour);
  if (difference < 0) {
      return 'Start time must be an hour after the current time'
  }
}

//automatic validation for worker counts when adding new dates
export const validateCustomWorkerCounts = (getValues, arrayName = 'projectDatesAttributes', index) => {
const values = getValues(`${arrayName}.${index}.projectWorkersAttributes`);
const totals = values.reduce((acc,item) => {
  const tot = parseInt(item.totalAmount);
  return isNaN(tot) ? acc:  acc + tot
},0);
if (totals === 0) {
  return "At least one worker must be selected"
}
};